<template>
  <el-drawer title="详情"
             v-model="modalShow"
             destroy-on-close
             :before-close="handleClose"
             size="700px">
    <div class="detail-info">
      <div class="reject-info"
           v-if="+detailData.status === 2">
        审核未通过：{{detailData.work_reason}}
      </div>
      <div class="info-cont">
        <div class="info-player">
          <div class="info-player-box"
               v-if="detailData.file_uri">
            <!-- <div class="cover-back"><i class="iconfont iconbofang1"></i></div> -->
            <hs-player :options="{url:detailData.file_uri, poster:detailData.cover_file_uri,duration:detailData.duration}"></hs-player>
          </div>
        </div>
        <div class="info-information">
          <div class="information-title">
            <div class="title-left">
              <span class="curr"></span>
              <span class="title-text">视频审核信息</span>
            </div>
            <div class="title-status"
                 :class="showStatus[detailData.status].class">
              <i class="iconfont iconshenhe2"></i>
              <span>
                {{showStatus[detailData.status].title}}
              </span>
            </div>
          </div>
          <div class="information-list">
            <div class="list-item">
              <span class="item-label">{{detailData.data_source !== 0 ? '分享人：' : '上传人：'}}</span>
              <span class="item-cont">{{detailData.nick_name}}</span>
            </div>
            <div class="list-item">
              <span class="item-label">视频标题：</span>
              <span class="item-cont item-title">{{detailData.title}}</span>
            </div>
            <div class="list-item">
              <span class="item-label">视频介绍：</span>
              <span class="item-cont">
                <span :class="!detailData.lookAll ? 'item-desc' : ''"
                      class="desc-text">{{detailData.introduction || '--'}}</span>
                <span class="lookAll"
                      v-if="!detailData.lookAll"
                      @click="lookAllDesc">展开全部</span>
              </span>
            </div>
            <div class="list-item">
              <span class="item-label">谁可以看：</span>
              <span class="item-cont">{{+detailData.auth_type === 0 ? '所有人' : '仅自己'}}</span>
            </div>
            <div class="list-item">
              <span class="item-label">分类：</span>
              <span class="item-cont">
                <span class="item-type"
                      v-for="(item, index) in detailData.work_type"
                      :key="index">
                  <span class="type-name">{{item.name}}</span>
                  <span class="type-line">-</span>
                </span>
              </span>
            </div>
            <div class="list-item">
              <span class="item-label">发布时间：</span>
              <span class="item-cont">{{$moment(detailData.created_at*1000).format('YYYY-MM-DD')}}</span>
            </div>
          </div>
          <div class="works-btns"
               v-if="+detailData.status === 0">
            <el-button class="reject"
                       @click="examine('reject')">驳回</el-button>
            <el-button class="reslove"
                       @click="examine('reslove')">通过</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, getCurrentInstance, toRefs, reactive, watch } from 'vue'

export default defineComponent({
  emits: ['examineWorks', 'closeDetail'],
  components: {
  },
  props: {
    showModal: {
      type: Boolean,
      default: () => false
    },
    worksId: {
      type: Number,
      default: () => 0
    }
  },
  setup (props, context) {
    watch(props, (val) => {
      data.modalShow = val.showModal
      data.id = val.worksId
      if (val.showModal) {
        getDetail()
      }
    })
    const { proxy } = getCurrentInstance()
    const data = reactive({ // 定义变量
      modalShow: false,
      id: 0,
      showStatus: {
        0: {
          title: '待审核',
          class: 'review',
          icon: 'iconshenhe2'
        },
        1: {
          title: '审核通过',
          class: 'green',
          icon: 'iconwanchengtongguo'
        },
        2: {
          title: '审核未通过',
          class: 'red',
          icon: 'iconbohui'
        }
      },
      detailData: {
        status: 0,
        url: ''
      },
      status: 0,
      work_reason: ''
    })
    const handleClose = () => {
      context.emit('closeDetail')
      data.modalShow = false
      data.detailData = {
        status: 0,
        url: ''
      }
    }

    const lookAllDesc = () => {
      data.detailData.lookAll = true
    }

    const examine = (type) => {
      if (type === 'reslove') {
        data.status = 1
        workExamine()
      } else {
        proxy.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[^\s+$]/,
          inputErrorMessage: '驳回原因不可以为空'
        }).then(({ value }) => {
          if (value.length > 100) {
            return proxy.$notify({
              title: '系统通知',
              message: '驳回原因不可超过100字',
              type: 'error'
            })
          }
          data.work_reason = value
          data.status = 2
          workExamine()
        })
      }
    }

    // 获取详情
    const getDetail = async () => {
      try {
        const sd = await proxy.$api.getWorksDetail({
          work_id: data.id
        })
        data.detailData = sd.data
        if (data.detailData.introduction.length > 66) {
          data.detailData.lookAll = false
        } else {
          data.detailData.lookAll = true
        }
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }

    // 作品审核
    const workExamine = async () => {
      try {
        await proxy.$api.postExaminework({
          work_id: data.id,
          status: data.status,
          work_reason: data.work_reason
        })
        proxy.$notify({
          title: '系统通知',
          message: '审核成功',
          type: 'success'
        })
        data.modalShow = false
        context.emit('examineWorks')
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }
    return {
      ...toRefs(data),
      handleClose,
      workExamine,
      examine,
      lookAllDesc,
      getDetail
    }
  }
})
</script>

<style lang="scss" scoped>
.detail-info {
  width: 100%;
  height: 100%;
  padding: 10px 30px 10px 10px;
  background-color: #fff;
  margin-top: 10px;
  overflow: auto;
  .reject-info {
    padding: 5px 10px;
    background-color: #fffcf0;
    color: #f09e00;
    font-size: 12px;
    margin-bottom: 20px;
    border: 1px solid #ffeaa3;
  }
  .info-cont {
    width: 100%;
    display: flex;
    .info-player {
      width: 224px;
      height: 389px;
      background-color: #000;
      position: relative;
      border-radius: 4px;
      .info-player-box {
        width: 100%;
        height: 100%;
        display: flex;
        .cover-back {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.1);
          i {
            font-size: 35px;
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
        }
      }
      // &:hover .cover-back {
      //   display: block;
      // }
    }
    .info-information {
      width: 400px;
      margin-left: 20px;
      .information-title {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        .title-left {
          display: flex;
          .curr {
            display: block;
            width: 8px;
            height: 8px;
            border: 2px solid #f54a4a;
            border-radius: 50%;
            margin-top: 4px;
            margin-right: 5px;
          }
          .title-text {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }
        .title-status {
          padding: 3px 10px;
          font-size: 12px;
          display: flex;
          i {
            font-size: 15px;
            margin-top: 1px;
            margin-right: 2px;
          }
          span {
            display: block;
          }
        }
        .review {
          color: #ff7e2a;
          background: #ffefe5;
        }
        .green {
          color: #01d356;
          background: #f3fff8;
        }
        .red {
          color: #f12200;
          background: #fff6f5;
        }
      }
      .information-list {
        margin-top: 20px;
        .list-item {
          display: flex;
          margin-bottom: 15px;
          span {
            font-size: 14px;
            color: #333333;
          }
          .item-label {
            min-width: 70px;
            text-align: right;
            margin-right: 5px;
          }
          .item-cont {
            flex: 1;
            color: #666;
            .item-type {
              span {
                color: #666;
              }
              &:last-child {
                .type-line {
                  display: none;
                }
              }
            }
          }
          .item-desc {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .item-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc-text {
            color: #666;
            word-break: break-all;
          }
          .lookAll {
            color: #1890ff;
            cursor: pointer;
          }
        }
      }
    }
    .works-btns {
      display: flex;
      margin-top: 20px;
      .reject {
        background-color: #ff4d4d;
      }
      .reslove {
        background-color: #1890ff;
      }
    }
  }
}
.works-btns::v-deep(.el-button) {
  padding: 0;
  margin: 0;
  width: 100px;
  line-height: 32px;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  border: none;
  margin-right: 20px;
  min-height: 32px !important;
}
</style>
